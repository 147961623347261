.sidenav-menu {
  @apply flex items-center md:px-6 p-5 leading-4 text-main font-semibold justify-start bg-white relative;


  @apply sm:bg-gray-50;


  &:hover :not(#logo) {
    @apply text-main-active;
  }

  &.prev {
    @apply rounded-br-lg;
  }

  &.active + a {
    @apply rounded-tr-lg;
  }
}
