.inputlistitem:after {
  content: '';

  width: 16px;
  height: 16px;

  background: url('./Assets/Remove.svg');

  position: absolute;
  top: 52%;
  right: 4px;
  transform: translateY(-50%);
}
