.hamburger-menu {
  width: 26px;
  height: 26px;
  position: relative;

  cursor: pointer;
}

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #303030;
  border-radius: 9px;
  opacity: 1;
  left: 0;
}

.hamburger-menu span:nth-child(1) {
  top: 0px;
}

.hamburger-menu span:nth-child(2),
.hamburger-menu span:nth-child(3) {
  top: 10px;
}

.hamburger-menu span:nth-child(4) {
  top: 20px;
}

.hamburger-menu.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.hamburger-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger-menu.open span:nth-child(4) {
  top: 0;
  width: 0%;
  left: 50%;
}
