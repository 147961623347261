@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

@import './assets/css/adminInput.css';
@import './assets/css/button.css';
@import './assets/css/globals.css';
@import './assets/css/login.css';
@import './assets/css/editor.css';
@import './assets/css/persona.css';
@import './assets/css/collapsible.css';
@import './assets/css/sidenav.css';
@import './assets/css/hamburger.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';

* {
    box-sizing: border-box;
  }
  
  body {
    background: white;
    scroll-behavior: smooth;
  }
  
  html.todesktop .drag {
    -webkit-app-region: drag;
  }
  
  *::-webkit-scrollbar {
    width: 8px;
  }
  
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #808080;
    border-radius: 10px;
  }
  
  .fade-in {
    animation: fadeIn ease 500ms;
    -webkit-animation: fadeIn ease 500ms;
    -moz-animation: fadeIn ease 500ms;
    -o-animation: fadeIn ease 500ms;
    -ms-animation: fadeIn ease 500ms;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }
  
      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }