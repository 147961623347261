.persona {
  @apply fixed bg-white p-4 rounded-lg shadow-lg border-t border-gray-100;
  @apply flex flex-col space-y-4;
  /* bottom: -80px;
  right: 25px; */
  z-index: 99999;
}

.persona-menu {
  position: absolute;
  /* left: 0; */
  @apply bg-white shadow-lg rounded-lg border border-gray-100;
  z-index: 999;
}
