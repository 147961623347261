.btn {
  @apply rounded py-2 px-2 sm:px-4 text-lg font-normal font-source flex items-center justify-center text-center;

  &.primary {
    @apply bg-ubblue text-white;

    &:hover {
      @apply bg-ubblue-hover;
    }

    &:disabled {
      @apply text-indigo-200;
    }
  }

  &.clear {
    @apply shadow-none text-indigo-600;
    &:hover {
      @apply text-indigo-700;
    }

    &:disabled {
      @apply text-indigo-200;
    }
  }

  &.outline-btn {
    @apply border-gray-400 text-main-active border rounded-md text-sm shadow-none;

    &:hover {
      @apply text-white bg-main;
    }

    &:disabled {
      @apply text-gray-200 border-gray-200;
    }
  }

  &.outline-indigo {
    @apply border border-ubblue text-ubblue shadow-none;
    @apply flex items-center justify-center;
    @apply py-1 rounded-full;

    &:hover {
      @apply bg-ubblue-hover text-white cursor-pointer;
    }

    &.with-bg {
      @apply bg-ubblue text-white;
    }
  }

  &.block {
    @apply w-full;
  }
}
