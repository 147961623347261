body {
  @apply bg-gray-50;
}

.public-DraftEditorPlaceholder-inner {
  font-style: italic;
  color: #bebebe;
  font-size: small;
}

.notepad-button {
  /* top: 10vh; */
  /* top: 50%; */
  /* top: calc(50vh - 150px / 2)); */
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.filter-none {
  filter: none;
}

.filter-grayscale {
  filter: grayscale(1) invert(1);
}

* {
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}

/* #layout {
  zoom: 0.85;
  -moz-transform: scale(0.85);
  -moz-transform-origin: 0 0;
} */

button,
input,
textarea {
  outline: none;
  &:focus,
  active {
    outline: none;
  }
}

.public-DraftEditor-content {
  color: #595959 !important;
}

@media (min-width: 1670px) {
  /*   #layout {
    zoom: 1;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
  } */
}

.shortcut-icon {
  fill: none;
  stroke-width: 3px;
  stroke: #491ec4;
}
.shortcut-icon:hover {
  stroke: #491ec4;
}
